/**
 * @File: constants vars here
 */
export const VALUE_TAG_MAX_LENGTH = 20;
export const VALUE_TAG_LIST_MAX = 30;
export const KEY_TOKEN = 'access_token';
export const KEY_ACCOUNT = 'access_account';

export const privateKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvJGaiiS3oLK9QXgm1jpzKe3g4jKRu0zXWqjaazh9NW13vdMcu3ctKT2+GqV9I7FMBgP69p9LX1hOXoSmagYB5Qku1Vrjx03mjnhcYaCleJzv7vksb8Rsx/Dd8pRCVoYvjsgawYB+oxnvlHKvk7d/XuHCOY02Tod21KpsBQ6Z9AwIDAQAB';

export const DICT_TYPE = [
  {
    id: 1,
    name: '系统预设',
  },
  {
    id: 2,
    name: '自定义',
  },
];

export const DICT_STATUS = [
  {
    id: 1,
    name: '启用',
  },
  {
    id: 2,
    name: '禁用',
  },
];

export const DATA_STATUS = [
  {
    id: 1,
    name: '启用',
  },
  {
    id: 2,
    name: '禁用',
  },
];


export const ROLE_STATUS = [
  {
    id: 1,
    name: '启用',
  },
  {
    id: 2,
    name: '禁用',
  },
];

export const USER_SEX = [
  {
    id: 1,
    name: '男',
  },
  {
    id: 2,
    name: '女',
  },
];

export default {
  DICT_TYPE,
  DICT_STATUS,
  ROLE_STATUS,
  USER_SEX,
  VALUE_TAG_MAX_LENGTH,
  VALUE_TAG_LIST_MAX,
  KEY_TOKEN,
  KEY_ACCOUNT,
  DATA_STATUS,
  privateKey,
};
