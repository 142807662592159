/**
 * @file：系统公共接口
 */
// 标签
import request from '@/utils/request';

export function requestTagList(params) {
  return request.post('/system/label/getLabelList', params);
}

export function requestDeleteTag(params) {
  return request.post('/system/label/deleteLabel', params);
}

export function requestUpdateTag(params) {
  return request.post('/system/label/updateLabel', params);
}

export function requestCreateTag(params) {
  return request.post('/system/label/saveLabel', params);
}
// 数据字典
// export function requestTagList(params) {
//   return request.post('/label/getLabelList', params);
// }
