/**
 * @class:系统页面整体布局结构  左右 结构
 * @author:daopian
 */
import React, { Suspense, useEffect } from 'react';
import { Switch, Link, Route, Redirect } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Spin, Dropdown } from 'antd';
import { SystemManage } from '@/api';
import { IMG_LOGO, IMG_BRAND } from '@/assets/img/index';
import IconFont from '@/assets/icon/index';
import Style from './index.module.css';
import withLocalStorage from '@/components/HOC/withLocalStorage';
import { KEY_TOKEN } from '@/constants';
import { menuCodeMatch, pathRoutesList } from '@/constants/menuIndexes';

const BreadcrumbItem = Breadcrumb.Item;
const { SubMenu } = Menu;
const { Sider, Header, Content } = Layout;
const { fetchLogout } = SystemManage;

class SpinSubscription {
  todoList = [];

  subscribe(fn) {
    this.todoList.push(fn);
  }

  broadcast() {
    this.todoList.forEach((fn) => fn());
  }
}
const spinSubscription = new SpinSubscription();

class DefaultLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      spin: false,
      openKeys: [],
      selectedKeys: [],
      menuList: props.accountInfo ? props.accountInfo.menuList : [],
      accountInfo: props.accountInfo,
      pathname: '',
      clientWidth: window.document.documentElement.clientWidth,
      clientHeight: window.document.documentElement.clientHeight,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const { menuList } = this.state;

    spinSubscription.subscribe(() =>
      this.setState(function (state) {
        return { spin: !state.spin };
      }),
    );
    menuList.forEach(({ code, subMenuList }) => {
      const subMenu = subMenuList.find((item) => history.location.pathname.indexOf(menuCodeMatch[item.code]) > -1);
      if (subMenu) {
        this.setState({ openKeys: [code], selectedKeys: [subMenu.code] });
      }
    });

    window.addEventListener('resize', () =>
      this.setState({
        clientWidth: window.document.documentElement.clientWidth,
        clientHeight: window.document.documentElement.clientHeight,
      }),
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', function () {});
  }

  componentDidUpdate() {
    if (!this.props.accountInfo && this.props.history.location.state && this.props.history.location.state.accountInfo) {
      this.setState({
        accountInfo: this.props.history.location.state.accountInfo,
        menuList: this.props.history.location.state.accountInfo.menuList,
      });
    }
  }

  handleShiftCollapse = () =>
    this.setState(function (state) {
      return { collapsed: !state.collapsed, openKeys: [] };
    });

  handleLogout = () => {
    const token = window.localStorage.getItem(KEY_TOKEN);
    fetchLogout({ token }).then(() => {
      window.localStorage.clear();
      this.props.history.push('/login');
    });
  };

  handleOpenMenu = (openKeys) => this.setState({ openKeys });

  handleSelectMenu = (res) => {
    const { menuList } = this.state;
    const { selectedKeys, key } = res;
    const menu = menuList.find(({ subMenuList }) => subMenuList.some(({ code }) => code === key));
    this.setState({ selectedKeys, openKeys: [menu.code] });
  };

  handleBackHome = () => this.props.history.push('/home');

  onRefreshPath = (pathname) => this.setState({ pathname });

  renderSlider(width) {
    const { openKeys, collapsed, menuList, selectedKeys } = this.state;
    const img = collapsed ? IMG_LOGO : IMG_BRAND;

    return (
      <Sider
        trigger={null}
        collapsible
        width={width}
        collapsed={collapsed}
        style={{ height: '100%', overflow: 'auto' }}
        collapsedWidth={width}
      >
        <div onClick={this.handleBackHome} className={Style.siderLogo}>
          <img src={img} alt="logo" height={20} />
        </div>
        <Menu
          mode="inline"
          theme="dark"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onSelect={this.handleSelectMenu}
          onOpenChange={this.handleOpenMenu}
        >
          {menuList.map((item) => {
            const title = (
              <span>
                <IconFont type={menuCodeMatch[item.code]} />
                <span>{item.name}</span>
              </span>
            );
            return (
              <SubMenu key={item.code} title={title}>
                {item.subMenuList.map(({ code, name }) => {
                  return (
                    <Menu.Item key={code}>
                      <Link to={menuCodeMatch[code]} title={name}>
                        {name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
        </Menu>
      </Sider>
    );
  }

  renderBreadCrumbs = () => {
    const { accountInfo, pathname } = this.state;
    const { location } = this.props;
    if (pathname === '/home' || pathname === '/') {
      // 渲染欢迎信息
      const { name } = accountInfo || {};
      return !name ? null : (
        <span className={Style.headerTitle}>
          你好，<span className={Style.nameTitle}>{name}</span>，欢迎进入华夏数字钢厂！
        </span>
      );
    }
    // 渲染面包屑
    const crumbs = [];
    const keys = pathname.split('/');
    keys.shift();
    keys.forEach((key, index) => {
      const url = `/${keys.slice(0, index + 1).join('/')}`;
      const result = pathRoutesList.find((route) => route.path === url);
      if (result) {
        crumbs.push(result);
      }
    });
    return (
      <Breadcrumb style={{ display: 'inline-block' }}>
        {crumbs.map((crumb) => {
          const pathLength = crumb.path.split('/').length;
          const actualPath = location.pathname.split('/').slice(0, pathLength).join('/');
          const isLast = crumbs.indexOf(crumb) === crumbs.length - 1;
          return (
            <BreadcrumbItem key={crumb.text}>
              {!isLast ? <Link to={actualPath}>{crumb.text}</Link> : crumb.text}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  };

  renderHeader(height) {
    const headerStyle = { height };
    const overlay = (
      <Menu>
        <Menu.Item onClick={this.handleLogout}>退出登录</Menu.Item>
      </Menu>
    );
    return (
      <Header style={headerStyle} className={Style.headerStyle}>
        <section>
          <IconFont
            type="iconshouqi"
            style={{ marginRight: 12, cursor: 'pointer' }}
            onClick={this.handleShiftCollapse}
          />
          {this.renderBreadCrumbs()}
        </section>
        <Dropdown overlay={overlay}>
          <IconFont type="iconyonghuzhongxin" style={{ cursor: 'pointer' }} />
        </Dropdown>
      </Header>
    );
  }

  renderContent(height, width) {
    return (
      <Content style={{ height, overflow: 'auto' }}>
        <Suspense fallback={<div />}>
          <Switch>
            {pathRoutesList.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <RouteWrapper onRefreshPath={this.onRefreshPath} {...props}>
                    <route.component height={height} width={width} {...props} {...route.params} />
                  </RouteWrapper>
                )}
              />
            ))}
            <Redirect to="/notFound" />
          </Switch>
        </Suspense>
      </Content>
    );
  }

  render() {
    const { collapsed, clientHeight, clientWidth, spin } = this.state;
    const leftWidth = collapsed ? 100 : 160;
    const rightWidth = clientWidth - leftWidth;
    const headerHeight = 40;
    const rightStyle = { width: rightWidth };

    return (
      <Spin spinning={spin}>
        <Layout style={{ height: clientHeight }} className={Style.layoutRoot}>
          {this.renderSlider(leftWidth)}
          <Layout style={rightStyle}>
            {this.renderHeader(headerHeight)}
            {this.renderContent(clientHeight - headerHeight, clientWidth - leftWidth)}
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export { spinSubscription };

function RouteWrapper(props) {
  const { onRefreshPath, children } = props;
  useEffect(function () {
    onRefreshPath(props.match.path);
  }, []);
  return <>{children}</>;
}
export default withLocalStorage(({ accountInfo }) => ({ accountInfo }), DefaultLayout);
