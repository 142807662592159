/**
 * @File: a request wrapper based on axios,
 * axios is a Promise based HTTP client for the browser and node.js,
 * https://github.com/axios/axios.
 */
import axios from 'axios';
import { biciNotification } from 'bici-transformers';
import { spinSubscription } from '@/pages/Layout/DefaultLayout';
import { KEY_TOKEN } from '@/constants';
// import { API_URI } from '@/config';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
let REQUEST_COUNT = 0;
let timer = null;
const instance = axios.create({ baseURL: `/api`, timeout: 45000 });
// const instance = axios.create({ baseURL: `${API_URI}/api`, timeout: 45000 });

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(KEY_TOKEN) || undefined;
    const headers = { ...config.headers, token };
    if (!REQUEST_COUNT) {
      spinSubscription.broadcast();
    }
    REQUEST_COUNT += 1;
    return { ...config, headers };
  },
  (error) => {
    const { status } = error;
    const message = codeMessage[status];
    if (status in codeMessage) {
      biciNotification.error({ message });
    }
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    const { data: res, config } = response;
    const { download = false, downloadName = 'default' } = config;
    const { code, data, msg, error } = res;
    REQUEST_COUNT -= 1;
    if (!REQUEST_COUNT) {
      spinSubscription.broadcast();
    }
    if (res instanceof window.Blob) {
      // for a a common requirements,
      // supprot download config for auto downloading，
      // downloadName also support a suffix.
      if (download) {
        const objectUrl = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = downloadName;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      }
      return res;
    }

    switch (code) {
      case 1000:
        return data;
      // other businesss code process logic here,
      // for example, in a project, code `4507` means token is not valid,
      // there would be a jump logic to deal with.
      case 2002:
        // token is out of date or not valid
        biciNotification.error({ message: '登录信息已过期,请重新登录' });
        window.localStorage.clear();
        if (timer) {
          window.clearInterval(timer);
        }
        timer = window.setTimeout(() => {
          window.location.href = '/login';
        }, 1500);
        return Promise.reject(data);
      default:
        biciNotification.error({ message: msg || error });
        return Promise.reject(data);
    }
    // spinSubscription.broadcast();
  },
  (response) => {
    const { error } = response;
    // other businesss code process logic here,
    // for example, in a project, code `4507` means token is not valid,
    // there would be a jump logic to deal with.
    biciNotification.error({ message: error || '服务器发生错误，请检查服务器。' });
    REQUEST_COUNT -= 1;
    if (!REQUEST_COUNT) {
      spinSubscription.broadcast();
    }
    return Promise.reject();
  },
);

export default instance;
