/**
 *@function
 */
import React from 'react';
import { KEY_ACCOUNT, KEY_TOKEN } from '@/constants';

export default function withLocalStorage(mapStorageToProps, Component) {
  const token = window.localStorage.getItem(KEY_TOKEN);
  const accountInfo = JSON.parse(window.localStorage.getItem(KEY_ACCOUNT));
  const data = mapStorageToProps({ token, accountInfo });

  return React.forwardRef((props, ref) => {
    return <Component {...props} {...data} ref={ref} />;
  });
}
