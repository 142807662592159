import { lazy } from 'react';

const Home = lazy(() => import('@/pages/Layout/Routes/Home'));
// 系统管理
const UserManage = lazy(() => import('@/pages/Layout/Routes/SystemManage/userManage')); // 用户管理
const UserInfo = lazy(() => import('@/pages/Layout/Routes/SystemManage/userInfo')); // 用户信息
const Dictionary = lazy(() => import('@/pages/Layout/Routes/SystemManage/dictionary')); // 数据字典
const RoleManage = lazy(() => import('@/pages/Layout/Routes/SystemManage/roleManage')); // 角色管理
const RoleDetail = lazy(() => import('@/pages/Layout/Routes/SystemManage/roleManage/RoleDetail')); // 角色管理
// 基础数据模块
const Catalog = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Catalog/List')); // 产品目录列表
const CatalogDetail = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Catalog/Detail')); // 产品目录详情
const Specification = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Specification/List')); // 产品/原料规格列表
const SpecificationDetail = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Specification/Detail')); // 产品及原料规格详情
const Steel = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Steel/List')); // 钢号列表
const SteelDetail = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Steel/Detail')); // 钢号详情
const UnitList = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Unit/List')); // 来料单位/外销单位列表
const UnitDetail = lazy(() => import('@/pages/Layout/Routes/BasicInfo/Unit/Detail')); // 来料单位/外销单位详情
const FurnaceSimulation = lazy(() => import('@/pages/Layout/Routes/ProductionManagement/FurnaceSimulation')); // 生产管理/炉内仿真
// 统计分析
const ProductForm = lazy(() => import('@/pages/Layout/Routes/Statistics/ProductForm'));
const ProductStatistics = lazy(() => import('@/pages/Layout/Routes/Statistics/ProductStatistics'));
const RawStatistics = lazy(() => import('@/pages/Layout/Routes/Statistics/RawStatistics'));
const menuCodeMatch = {
  XTGL: 'iconxitongpeizhi',
  XTGL_YHGL: '/system/user',
  XTGL_GRXX: '/system/account',
  XTGL_SJZD: '/system/dictionary',
  XTGL_JSGL: '/system/role',
  JCSJ: 'iconjichushuju',
  JCSJ_CPML: '/catalog',
  JCSJ_CPGG: '/specification',
  JCSJ_YLGG: '/materials',
  JCSJ_GH: '/steel',
  SCGL: 'iconjichushuju',
  JCSJ_LLDW: '/suppliedUnit',
  JCSJ_WXDW: '/exportUnit',
  SCGL_LNFZ: '/furnaceSimulation', // 炉内仿真
  TJFX: 'icontongjifenxi',
  TJFX_SCQKTJ: '/productStatistics', // 生产情况统计
  TJFX_YLKCTJ: '/rawStatistics', // 原料库存统计
  TJFX_CPSFYBB: '/productForm', // 产品收发月报表
};

const pathRoutesList = [
  { path: '/', component: Home, text: '', exact: true },
  { path: '/home', component: Home, text: '', exact: true },
  // 系统管理
  { path: '/system/user', component: UserManage, exact: false, text: '用户管理' },
  { path: '/system/account', component: UserInfo, exact: false, text: '个人信息' },
  { path: '/system/dictionary', component: Dictionary, exact: false, text: '数据字典' },
  { path: '/system/role', component: RoleManage, exact: true, text: '角色管理' },
  { path: '/system/role/detail/:id', component: RoleDetail, exact: true, text: '详情' },
  // 基础数据模块
  { path: '/catalog', component: Catalog, text: '产品目录', exact: true },
  { path: '/catalog/create', component: CatalogDetail, text: '新建', exact: true },
  { path: '/catalog/detail/:id', component: CatalogDetail, text: '详情', exact: true },
  { path: '/specification', component: Specification, text: '产品规格', exact: true, params: { type: 1 } },
  { path: '/specification/create', component: SpecificationDetail, text: '新建', exact: true, params: { type: 1 } },
  { path: '/specification/detail/:id', component: SpecificationDetail, text: '详情', exact: true, params: { type: 1 } },
  { path: '/materials', component: Specification, text: '原料规格', exact: true, params: { type: 2 } },
  { path: '/materials/create', component: SpecificationDetail, text: '新建', exact: true, params: { type: 2 } },
  { path: '/materials/detail/:id', component: SpecificationDetail, text: '详情', exact: true, params: { type: 2 } },
  { path: '/steel', component: Steel, text: '钢号', exact: true },
  { path: '/steel/create', component: SteelDetail, text: '新建', exact: true },
  { path: '/steel/detail/:id', component: SteelDetail, text: '详情', exact: true },
  { path: '/suppliedUnit', component: UnitList, text: '来料单位', exact: true, params: { type: 1 } },
  { path: '/suppliedUnit/create', component: UnitDetail, text: '新建', exact: true, params: { type: 1 } },
  { path: '/suppliedUnit/detail/:id', component: UnitDetail, text: '详情', exact: true, params: { type: 1 } },
  { path: '/exportUnit', component: UnitList, text: '外销单位', exact: true, params: { type: 2 } },
  { path: '/exportUnit/create', component: UnitDetail, text: '新建', exact: true, params: { type: 2 } },
  { path: '/exportUnit/detail/:id', component: UnitDetail, text: '详情', exact: true, params: { type: 2 } },
  // 生产管理
  { path: '/furnaceSimulation', component: FurnaceSimulation, text: '炉内仿真', exact: true },
  // 统计分析
  { path: '/productStatistics', component: ProductStatistics, text: '生产情况统计', exact: true },
  { path: '/rawStatistics', component: RawStatistics, text: '原料库存统计', exact: true },
  { path: '/productForm', component: ProductForm, text: '产品收发月报表', exact: true },
];

export { menuCodeMatch, pathRoutesList };
