/**
 * @file 生产管理 
 */
import request from '@/utils/request';

// -------------------炉内仿真
// 仿真列表
export function requestFurnaceProcessList(params) {
    return request.post('/storage/furnace/processList', params);
}
export function requestFurnaceList(params) {
    return request.post('/storage/furnace/list', params);
}
// 保存
export function saveFurnace(params) {
    return request.post('/storage/furnace/save', params);
}
// 更新
export function updateFurnace(params) {
    return request.post('/storage/furnace/update', params);
}
