import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { KEY_TOKEN } from '@/constants';
import DefaultLayout from '@/pages/Layout/DefaultLayout';

const Login = lazy(() => import('@/pages/Login'));
// const DefaultLayout = lazy(() => import('@/pages/Layout/DefaultLayout'));

// BrowserRouter uses the HTML5 history API,
// support pushState, replaceState and the popstate event,
// it will keep your UI in sync with the URL.

export default function App() {
  const token = window.localStorage.getItem(KEY_TOKEN);
  if (!token && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }

  return (
    <ConfigProvider locale={zhCN}>
      <Suspense fallback={<div />}>
        <Router>
          <Switch>
            <Route path="/login" exact  component={Login} />
            <Route path="/"     component={DefaultLayout} />
          </Switch>
        </Router>
      </Suspense>
    </ConfigProvider>
  );
}
