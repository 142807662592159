// 图片目录
import IMG_LOGO from './logo.png';
import IMG_BRAND from './brand.png';
import IMG_DESC_Empty from './descEmpty.png';
import IMG_Role_Empty from './roleEmpty.png';
import IMG_Title from './title.png';
import IMG_PLUS from './uploadFile.png';
import IMG_UPLOAD from './upLoadingExcel.png';
import IMG_EXCEL from './iconExcel.png';
import IMG_Refresh from './refresh.png';
import IMG_EMPTY from './empty.png';

export {
  IMG_BRAND,
  IMG_LOGO,
  IMG_DESC_Empty,
  IMG_Role_Empty,
  IMG_Title,
  IMG_PLUS,
  IMG_UPLOAD,
  IMG_Refresh,
  IMG_EXCEL,
  IMG_EMPTY,
};
