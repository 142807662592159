/**
 * @file 基础信息模块api
 */
import request from '@/utils/request';

// 产品目录
export function requestCatalogList(params) {
  return request.post('/basedata/product/list', params);
}

export function requestDeleteCatalog(params) {
  return request.post('/basedata/product/delete', params);
}

export function requestCatalogDetail(params) {
  return request.get('/basedata/product/detail', { params });
}

export function requestCreateCatalog(params) {
  return request.post('/basedata/product/save', params);
}

export function requestUpdateCatalog(params) {
  return request.post('/basedata/product/update', params);
}

// 产品规格
export function requestSpecificationList(params) {
  return request.post('/basedata/standard/list', params);
}

export function requestDeleteSpecification(params) {
  return request.post('/basedata/standard/delete', params);
}

export function requestSpecificationDetail(params) {
  return request.get('/basedata/standard/detail', { params });
}

export function requestCreateSpecification(params) {
  return request.post('/basedata/standard/save', params);
}

export function requestUpdateSpecification(params) {
  return request.post('/basedata/standard/update', params);
}

// 钢号
export function requestSteelList(params) {
  return request.post('/basedata/steel/list', params);
}

export function requestDeleteSteel(params) {
  return request.post('/basedata/steel/delete', params);
}

export function requestSteelDetail(params) {
  return request.post('/basedata/steel/detail', params);
}

export function requestCreateSteel(params) {
  return request.post('/basedata/steel/save', params);
}

export function requestUpdateSteel(params) {
  return request.post('/basedata/steel/update', params);
}
// 单位
export function requestCompanyList(params) {
  return request.post('/basedata/company/list', params);
}

export function requestDeleteCompany(params) {
  return request.post('/basedata/company/delete', params);
}

export function requestCompanyDetail(params) {
  return request.post('/basedata/company/detail', params);
}

export function requestCreateCompany(params) {
  return request.post('/basedata/company/save', params);
}

export function requestUpdateCompany(params) {
  return request.post('/basedata/company/update', params);
}
