/**
 * @File: 登录相关接口
 */
import request from '@/utils/request';

// 登录
const fetchLogin = (params) => request.post('/system/user/login', params);

// 获取用户权限信息，权限菜单 + 用户信息
const fetchAccountInfo = (params) => request.post('/system/user/getUserInfo', params);

// 退出登录
const fetchLogout = (params) => request.post('/system/user/logout', params);

// 修改密码
const fetchUpdatePassword = (params) => request.post('/system/user/update/password', params);

// 查询系统标签
const fetchLabelList = (params) => request.post('/system/label/getLabelList', params);

// 查询系统标签下的标签数据
const fetchTagList = (params) => request.post('/system/tag/getTagPageList', params);

// 查询标签下的字典数据
const fetchDictList = (params) => request.post('/system/dict/getDictPageList', params);

// 修改标签
const updateTag = (params) => request.post('/system/tag/updateTag', params);

// 查询标签
const fetchTagDetail = (params) => request.get('/system/tag/getTagById', { params });

// 查询标签
const fetchTagListByCode = (params) => request.get('/system/tag/getTagByCode', { params });

// 查询字典详情
const fetchDictDetail = (params) => request.get('/system/dict/getDictById', { params });

// 新增字典
const createDict = (params) => request.post('/system/dict/saveDict', params);

// 更新字典
const updateDict = (params) => request.post('/system/dict/updateDict', params);

// 删除字典
const deleteDict = (params) => request.post('/system/dict/deleteDict', params);

// 更新字典状态
const updateDictStatus = (params) => request.post('/system/dict/updateDictStatus', params);

// 获取角色列表数据
const fetchRoleList = (params) => request.post('/system/role/getRolePageList', params);

// 添加角色
const createRole = (params) => request.post('/system/role/saveRole', params);

// 修改角色
const updateRole = (params) => request.post('/system/role/updateRole', params);

// 删除角色
const deleteRole = (params) => request.post('/system/role/deleteRole', params);

// 变更角色状态
const updateRoleStatus = (params) => request.post('/system/role/updateRoleStatus', params);

// 修改角色，菜单，方法的关联关系
const updateRoleMenuMapping = (params) => request.post('/system/role/updateRoleMenuFunctionMapping', params);

// 添加角色用户权限
const saveUserRoleMapping = (params) => request.post('/system/role/saveUserRoleMapping', params);

// 从角色中移除用户
const removeRoleUser = (params) => request.post('/system/role/roleRemoveUser', params);

// 获取角色详情
const fetchRoleDetail = (params) => request.get('/system/role/getRoleById', { params });

const fetchUpdateRoleTask = (params) => request.post('/system/role/updateRoleTaskMapping', params);

// 获取角色菜单
const fetchRoleMenus = (params) => request.post('/system/role/getMenuList', params);

// 获取用户信息
const updateUserInfo = (params) => request.post('/system/user/updateUser', params);

// 修改密码
const updatePassword = (params) => request.post('/system/user/updatePassword', params);

// 获取当前可选择的标签
const fetchTagsList = (params) => request.post('/system/label/getLabelList', params);

// 保存标签
const saveTags = (params) => request.post('/system/label/saveLabel', params);

// 更新标签
const updateTags = (params) => request.post('/system/label/updateLabel', params);

// 删除标签
const deleteTags = (params) => request.post('/system/label/deleteLabel', params);

// 获取扁平化的角色菜单
const fetchFlatRoleMenus = (params) => request.post('/system/role/getFunctionMenuList', params);

// 获取用户列表
const fetchUserList = (params) => request.post('/system/user/getUserPageList', params);

// 添加用户
const createUser = (params) => request.post('/system/user/saveUser', params);

// 编辑用户
const updateUser = (params) => request.post('/system/user/updateUser', params);

// 删除用户
const deleteUser = (params) => request.post('/system/user/deleteUser', params);

// 重置用户密码
const resetUserPassword = (params) => request.post('/system/user/resetPassword', params);

// 批量导入用户数据
const createUserBatch = (params) => request.post('/system/user/importUserTemplate', params);

// 更新用户状态
const updateUserDataStatus = (params) => request.post('/system/user/updateUserDataStatus', params);

// 获取用户信息
const fetchUserDetail = (params) => request.get('/system/user/getUserById', { params });

// 获取部门树
const fetchDepartTree = (params) => request.post('/system/dept/getDeptTreeList', params);

// 添加部门
const createDepart = (params) => request.post('/system/dept/saveDept', params);

// 修改部门
const updateDepart = (params) => request.post('/system/dept/updateDept', params);

// 删除部门
const deleteDepart = (params) => request.post('/system/dept/deleteDept', params);

// 部门上移
const departUp = (params) => request.post('/system/dept/deptUp', params);

// 部门下移
const departDown = (params) => request.post('/system/dept/deptDown', params);

// 导出用户模板Excel
const downloadUserExcel = (params) => {
  return request.get('/system/user/exportUserTemplate', { params, responseType: 'blob' });
};

// 获取角色集合
const fetchRoleData = (params) => request.post('/system/role/getRoleList', params);

// 获取菜单下的权限按钮
const fetchMenuFunctions = (params) => request.post('/system/role/getFunctionList', params);

export {
  fetchLogin,
  fetchAccountInfo,
  fetchLogout,
  fetchUpdatePassword,
  fetchLabelList,
  fetchTagList,
  fetchTagListByCode,
  fetchDictList,
  updateTag,
  fetchTagDetail,
  fetchDictDetail,
  createDict,
  updateDict,
  deleteDict,
  updateDictStatus,
  fetchRoleList,
  createRole,
  updateRole,
  deleteRole,
  updateRoleStatus,
  updateRoleMenuMapping,
  saveUserRoleMapping,
  removeRoleUser,
  fetchRoleDetail,
  fetchRoleMenus,
  updateUserInfo,
  updatePassword,
  fetchUserList,
  fetchTagsList,
  saveTags,
  updateTags,
  deleteTags,
  fetchUserDetail,
  fetchFlatRoleMenus,
  createUser,
  updateUser,
  deleteUser,
  resetUserPassword,
  createUserBatch,
  updateUserDataStatus,
  fetchDepartTree,
  createDepart,
  updateDepart,
  deleteDepart,
  departUp,
  departDown,
  downloadUserExcel,
  fetchRoleData,
  fetchMenuFunctions,
  fetchUpdateRoleTask,
};
