/**
 * @File: api interface here
 */
import {
  requestCatalogDetail,
  requestCatalogList,
  requestCreateCatalog,
  requestDeleteCatalog,
  requestUpdateCatalog,
  requestSpecificationList,
  requestCreateSpecification,
  requestDeleteSpecification,
  requestSpecificationDetail,
  requestUpdateSpecification,
  requestCreateSteel,
  requestDeleteSteel,
  requestSteelDetail,
  requestSteelList,
  requestUpdateSteel,
  requestCompanyDetail,
  requestCompanyList,
  requestCreateCompany,
  requestDeleteCompany,
  requestUpdateCompany,
} from './BasicInfo';
import * as SystemManage from './SystemManage';
import * as ProductionManagement from './ProductionManagement';
import * as StatisticsApi from './Statistics';
import { requestCreateTag, requestDeleteTag, requestTagList, requestUpdateTag } from './system';

export {
  requestUpdateCatalog,
  requestDeleteCatalog,
  requestCreateCatalog,
  requestCatalogList,
  requestCatalogDetail,
  requestSpecificationList,
  requestCreateSpecification,
  requestDeleteSpecification,
  requestSpecificationDetail,
  requestUpdateSpecification,
  requestCreateTag,
  requestDeleteTag,
  requestTagList,
  requestUpdateTag,
  requestCreateSteel,
  requestDeleteSteel,
  requestSteelDetail,
  requestSteelList,
  requestUpdateSteel,
  requestCompanyDetail,
  requestCompanyList,
  requestCreateCompany,
  requestDeleteCompany,
  requestUpdateCompany,
  SystemManage,
  ProductionManagement,
  StatisticsApi,
};
