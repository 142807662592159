/**
 * @File: 统计
 */
import request from '../utils/request';
// 物资收发月报表
function requestGoodsReceiveMonthList(params, config) {
  return request.post('/statistic/creditBalance/goodsReceiveMonthList', params, config);
}
// 产品收发月报表 --- 详情列表数据
function requestProductReceiveMonthDetailList(params, config) {
  return request.post('/statistic/creditBalance/productReceiveMonthDetailList', params, config);
}
// 产品收发月报表 --- 饼图数据
function requestProductReceiveMonthTitle(params, config) {
  return request.post('/statistic/creditBalance/productReceiveMonthTitle', params, config);
}
// 生产情况统计报表
function requestProductReport(params, config) {
  return request.post('/statistic/creditBalance/productReport', params, config);
}
// 库存统计报表
function requestStorageReport(params, config) {
  return request.post('/statistic/creditBalance/storageGoodsReportList', params, config);
}

function requestUnQualifiedReport(params, config) {
  return request.post('/statistic/creditBalance/unqualifiedReport', params, config);
}

export {
  requestGoodsReceiveMonthList,
  requestProductReceiveMonthDetailList,
  requestProductReceiveMonthTitle,
  requestProductReport,
  requestStorageReport,
  requestUnQualifiedReport,
};
